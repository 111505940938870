// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import { useEffect, useCallback, useState, useMemo } from "react"
import { useLocaleInfo } from "framer"

const errorsEN = {
    error: "Error",
    gen: "Sorry, an error occured when creating your demo account.",
    invalid: "Sorry, some of the fields were invalid.",
    existing: "Sorry, this merchant name already exists.",
    existing_user: "Sorry, your email already has a demo account.",
    please: "Please",
    tryAgain: "try again",
}

const errorsFR = {
    error: "Erreur",
    gen: "Désolé, une erreur s'est produite lors de la création de votre compte démo.",
    invalid: "Désolé, certains champs ne sont pas valides.",
    existing: "Désolé, ce nom de commerçant existe déjà.",
    existing_user: "Sorry, votre adresse courriel possède déjà un compte démo.",
    please: "Veuillez",
    tryAgain: "réessayer",
}

const DataErrors = {
    DUPLICATE_ENTRY: "DUPLICATE_ENTRY",
    INVALID_UPDATE: "INVALID_UPDATE",
    HAS_DEPENDENCIES: "HAS_DEPENDENCIES",
    NOT_FOUND: "NOT_FOUND",
}

const SignUpErrors = {
    DuplicateUser: "DUPLICATE_USER",
}

const APIErrors = {
    VALIDATION: "VALIDATION",
    BAD_REQUEST: "BAD_REQUEST",
    NOT_FOUND: "NOT_FOUND",
    UNAUTHORIZED: "UNAUTHORIZED",
    FORBIDDEN: "FORBIDDEN",
    UNKNOWN: "UNKNOWN",
}

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Thing(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    const { activeLocale } = useLocaleInfo()

    const handleReady = useCallback(() => {}, [])

    const handleReload = () => {
        window.location.href = window.location.href
    }

    const errorMsgs = useMemo(
        () => (activeLocale.code === "fr" ? errorsFR : errorsEN),
        [activeLocale]
    )

    const parseDemoResponse = (res) => {
        // console.log("res", res)
        var cleaned = res?.replace(/'*/g, "")
        if (cleaned === "") {
            // window.location.href = "https://cashflow.io/email-verification/"
            window.location.href = window.location.origin + "/confirmation"
            return
        }

        hideLoading()

        try {
            var resp = JSON.parse(cleaned)

            if (resp.details === DataErrors.DUPLICATE_ENTRY) {
                displayExisting()
                return
            }
            if (resp.details === SignUpErrors.DuplicateUser) {
                displayExistingUser()
                return
            }

            if (resp.errorType === APIErrors.VALIDATION) {
                displayInvalid()
                return
            }
            displayError()
        } catch (e) {
            displayError()
        }
    }
    const showLoading = useCallback(() => {
        setIsLoading(true)
    }, [])
    const hideLoading = useCallback(() => {
        setIsLoading(false)
    }, [])
    const displayExisting = useCallback(() => {
        setHasError(true)
        setErrorTitle(errorMsgs.error)
        setErrprDesc(errorMsgs.existing)
    }, [errorMsgs])

    const displayExistingUser = useCallback(() => {
        setHasError(true)
        setErrorTitle(errorMsgs.error)
        setErrprDesc(errorMsgs.existing_user)
    }, [errorMsgs])

    const displayInvalid = useCallback(() => {
        setHasError(true)
        setErrorTitle(errorMsgs.error)
        setErrprDesc(errorMsgs.invalid)
    }, [errorMsgs])
    const displayError = useCallback(() => {
        setHasError(true)
        setErrorTitle(errorMsgs.error)
        setErrprDesc(errorMsgs.gen)
    }, [errorMsgs])
    const hideForm = useCallback(() => {
        setShowForm(false)
    }, [])

    const handlePostData = useCallback((data) => {
        const response = fetch(
            "https://api.demo.cashflow.io/v1.0/merchants/demo_o",
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "omit", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer rbc512c5_4f7c=55fwWbp20$eeed77a1fd0",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            }
        )
            .then((res) => {
                res.text().then((res) => {
                    parseDemoResponse(res)
                })
            })
            .catch((err) => {
                // parseDemoResponse(err)
                hideLoading()
                displayError()
            })

        //    jQuery.ajax({
        //                             url: "https://api.demo.cashflow.io/v1.0/merchants/demo_o",
        //                             type: "post",
        //                             data: JSON.stringify(postData),
        //                             contentType: "application/json; charset=utf-8",
        //                             //xhrFields: { withCredentials: true },
        //                             dataType: "json",
        //                             headers: {
        //                                 Authorization:
        //                                     "Bearer rbc512c5_4f7c=55fwWbp20$eeed77a1fd0",
        //                             },
        //                             success: function (data) {
        //                                 parseDemoResponse(data)
        //                                 //   window.location.href = 'https://cashflow.io/email-verification/';
        //                             },
        //                             error: function (xhr, status, errorThrown) {
        //                                 // console.log('just errored', {xhr,status,errorThrown})
        //                                 parseDemoResponse(xhr.responseText)
        //                                 hideLoading()
        //                                 displayError()
        //                                 // console.log("Demo error", data);
        //                             },
        //                         })
    }, [])

    const [hasError, setHasError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [errorTitle, setErrorTitle] = useState("")
    const [errorDesc, setErrprDesc] = useState("")

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "//js.hsforms.net/forms/shell.js"
        document.body.appendChild(script)

        script.addEventListener("load", () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "6436120",
                    formId:
                        activeLocale.code === "fr"
                            ? "351a7c12-2ef8-4aff-863d-9049ecfb3682"
                            : "a4cb653f-a919-43fa-8c35-f35cd7703da1",
                    target: "#hubspotForm",
                    onFormReady: (form) => {
                        handleReady()
                    },
                    onFormSubmitted: function ($form, data) {
                        var vals = data.submissionValues
                        var postData = {
                            name: vals.company,
                            contactInfo: {
                                phone: vals.phone,
                                email: vals["0-2/business_email"],
                                website: vals.website,
                                address: {
                                    street: vals.address,
                                    suite: "",
                                    city: vals.city,
                                    region: vals.state,
                                    postalCode: vals["0-2/zip"],
                                    country: vals.country,
                                },
                            },
                            additional: {
                                industry:
                                    vals.business_industry === "none"
                                        ? ""
                                        : vals.business_industry,
                                revenue:
                                    vals.revenue === "none" ? "" : vals.revenue,
                                regionOfBusiness:
                                    vals.primary_region_of_business_multi,
                            },
                            primaryUser: {
                                name:
                                    vals.firstname.trim() +
                                    " " +
                                    vals.lastname.trim() /*vals.fullname,*/,
                                email: vals.email,
                                language: vals.language,
                                title: vals.title,
                            },
                        }

                        hideForm()
                        showLoading()
                        handlePostData(postData)

                        // jQuery.ajax({
                        //     url: "https://api.demo.cashflow.io/v1.0/merchants/demo_o",
                        //     type: "post",
                        //     data: JSON.stringify(postData),
                        //     contentType: "application/json; charset=utf-8",
                        //     //xhrFields: { withCredentials: true },
                        //     dataType: "json",
                        //     headers: {
                        //         Authorization:
                        //             "Bearer rbc512c5_4f7c=55fwWbp20$eeed77a1fd0",
                        //     },
                        //     success: function (data) {
                        //         parseDemoResponse(data)
                        //         //   window.location.href = 'https://cashflow.io/email-verification/';
                        //     },
                        //     error: function (xhr, status, errorThrown) {
                        //         // console.log('just errored', {xhr,status,errorThrown})
                        //         parseDemoResponse(xhr.responseText)
                        //         hideLoading()
                        //         displayError()
                        //         // console.log("Demo error", data);
                        //     },
                        // })
                    },
                })
            }
        })

        // fetch("https://www.google.com").then((response) => {
        //     console.log(response)
        // })
    }, [])

    return (
        <>
            {hasError && (
                <>
                    <div className="alertBox" style={alertStyle}>
                        <p style={alertTitle}>{errorTitle}</p>
                        <p style={alertDesc}>
                            {errorDesc}
                            <br />
                            {errorMsgs.please}{" "}
                            <span style={styleLink} onClick={handleReload}>
                                {errorMsgs.tryAgain}
                            </span>
                        </p>
                    </div>
                </>
            )}
            <div style={{ maxWidth: "300px" }}>
                {isLoading && (
                    <div style={{ textAlign: "center" }}>
                        <img
                            decoding="async"
                            loading="lazy"
                            src="https://framerusercontent.com/images/GvM0jMIeC7woUOiU3uqYPkDiEHA.gif"
                            alt=""
                            style={{
                                display: "block",
                                width: "133px",
                                height: "75px",
                                maxWidth: "100%",
                                borderRadius: "inherit",
                                objectPosition: "center center",
                                objectFit: "cover",
                                imageRendering: "auto",
                            }}
                        />

                        <p
                            style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                marginLeft: "8px",
                                fontSize: "20px",
                            }}
                        >
                            Please wait...
                        </p>
                    </div>
                )}
                <div
                    id="hubspotForm"
                    style={{
                        visibility: showForm ? "visible" : "hidden",
                        position: showForm ? "static" : "absolute",
                    }}
                ></div>
            </div>
        </>
    )
}

// <div
//     style={{
//         width: "24px",
//         height: "24px",
//         display: "inline-block",
//         verticalAlign: "middle",
//     }}
// >
//     <div>
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             focusable="false"
//             viewBox="0 0 24 24"
//             color="var(--token-5f9c34e1-c1a8-415b-a74b-c92716a82bf1, rgb(3, 169, 244))"
//             style={{
//                 userSelect: "none",
//                 width: "100%",
//                 height: "100%",
//                 display: "inline-block",
//                 fill: "var(--token-5f9c34e1-c1a8-415b-a74b-c92716a82bf1, rgb(3, 169, 244))",
//                 flexShrink: "0",
//             }}
//         >
//             <path d="M6 2l.01 6L10 12l-3.99 4.01L6 22h12v-6l-4-4 4-3.99V2H6zm10 14.5V20H8v-3.5l4-4 4 4z"></path>
//         </svg>
//     </div>
// </div>

const alertStyle = {
    backgroundColor: "rgba(255, 87, 87, 0.20)",
    boxShadow: "rgba(0, 0, 0, 0.05) 1px 1px 12px 4px",
    borderRadius: "8px",
    padding: "8px 12px",
    marginBottom: "32px",
}

const alertTitle = {
    fontFamily: 'Inter, "Inter Placeholder", sans-serif',
    color: "rgb(97, 97, 97)",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "10px",
}

const alertDesc = {
    fontFamily: 'Inter, "Inter Placeholder", sans-serif',
    color: "rgb(97, 97, 97)",
    fontSize: "14px",
    fontWeight: 400,
}

const styleLink = {
    fontFamily: 'Inter, "Inter Placeholder", sans-serif',
    color: "rgb(3, 169, 244)",
    fontSize: "14px",
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "underline",
}
